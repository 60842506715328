<template>
    <div id="VVVClaimsListLegend"
        class="d-flex justify-content-start align-items-center gap-3 ps-2 ps-lg-0 overflow-auto py-3">
        <p class="text-uppercase fw-bold text-center my-auto" style="font-size: 13px;">Legend: </p>
        <template v-for="legend in legends" :key="legend.text">
            <div class="px-3 py-1 rounded-5 shadow-sm text-nowrap legend-item"
                :style="[{ fontSize: '12px', background: legend.color }]"
                v-if="!(legend.text === 'Claimed by others' && !isAdmin)">
                <p class="align-middle text-center m-auto text-uppercase fw-bold" style="font-size: 11px;">
                    {{ legend.text }} {{ legend.text === 'Claimed' && isAdmin ? 'by you' : '' }}
                </p>
            </div>
        </template>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';

defineProps({
    isAdmin: Boolean,
    selectedUserId: String
});

const legends = [
    { text: 'Unclaimed', color: '#B2BEB5' },
    { text: 'Claimed', color: '#90EE90' },
    { text: 'Claimed by others', color: '#f7e4cd' },
];
</script>
